<div class="p-4" style="background-color: rgb(245, 238, 230)">
  <div class="container">
    <div [ngStyle]="{'background-image': 'url(' + getClientBannerUrl() + ')'}"
         class="custom-background"
         style="background-position: center top;">
    </div>

    <div style=" background-color: rgb(156, 86, 6); border-top-left-radius: 8px; border-top-right-radius: 8px; height: 10px; max-width: 640px; margin-right: auto; margin-left: auto; margin-top: 20px;">

    </div>
    <div style="max-width: 640px; width: 100%; padding: 20px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); background: white; margin-right: auto; margin-left: auto; ">
      <div class="form-wrapper">
        <form [formGroup]="dandiForm" (ngSubmit)="onSubmit()" class="form" style="border-top-right-radius: 0px; border-top-left-radius: 0px; ">

          <div class="section-container">
            <h3 class="section-title">Education</h3>
            <!-- GCSE Results Section -->
            <h3 class="section-subtitle smaller-heading">GCSE or Equivalent Qualifications</h3>
            <div class="form-group">
              <label for="gcseYear">*Please select the year that you took your GCSE or equivalent qualifications</label>
              <select id="gcseYear" formControlName="gcseYear">
                <option *ngFor="let year of gsceYears" [value]="year">{{year}}</option>
              </select>
            </div>

            <div class="form-group">
              <label>*Please select the school where you sat the majority of your GCSE or equivalent qualifications. If your school is not listed, please select ‘Unavailable’.</label>
              <input type="text" matInput formControlName="gcseSchool" [matAutocomplete]="autoGSCE">
              <mat-autocomplete #autoGSCE="matAutocomplete">
                <mat-option *ngFor="let option of filteredGcseSchools | async" [value]="option">
                  {{option}}
                </mat-option>
              </mat-autocomplete>
            </div>

            <!--GSCE Results Section-->
            <!-- A Level Results Section -->
            <h3 class="section-subtitle smaller-heading">A-level or Equivalent Qualifications</h3>
            <div class="form-group">
              <label for="aLevelYear">*Please select the year that you took, or are expected to complete, the majority your A-Level or equivalent qualifications</label>
              <select id="aLevelYear" formControlName="aLevelYear">
                <option *ngFor="let year of aLevelYears" [value]="year">{{year}}</option>
              </select>
            </div>

            <div class="form-group">
              <label>*Please select the school where you sat the majority of your A-level or equivalent qualifications. If your school is not listed, please select ‘Unavailable’.</label>
              <input type="text" matInput formControlName="aLevelSchool" [matAutocomplete]="autoALevel">
              <mat-autocomplete #autoALevel="matAutocomplete">
                <mat-option *ngFor="let option of filteredALevelSchools | async" [value]="option">
                  {{option}}
                </mat-option>
              </mat-autocomplete>
            </div>

            <div class="form-group">
              <label>*Did you complete or are you expected to complete:</label>
              <div style="display: flex; align-items: center;">
                <label style="order: 1;">
                  <input type="radio" formControlName="aLevelCompletion" value="3ALevels">
                </label>
                <div style="order: 2; margin-left: 20px;">
                  A minimum of 3 A-levels
                </div>
              </div>
              <div style="display: flex; align-items: center;">
                <label style="order: 1;">
                  <input type="radio" formControlName="aLevelCompletion" value="otherEquivalent">
                </label>
                <div style="order: 2; margin-left: 20px;">
                  Other A-level equivalent qualifications
                </div>
              </div>
            </div>

            <div *ngIf="is3ALevelsSelected()">
              <h3 class="section-subtitle smaller-heading">A-levels - Minimum of 3</h3>

              <div class="form-group">
                <label for="aLevelGrade3">*Subject 1 / Grade 1 (including predicted grades)</label>
                <div class="flex-container">
                  <input id="aLevelSubject1" type="text" formControlName="aLevelSubject1" placeholder="Enter Subject 1">
                  <select id="aLevelGrade1" formControlName="aLevelGrade1">
                    <option *ngFor="let grade of aLevelGradeOptions" [value]="grade">{{grade}}</option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label for="aLevelGrade3">*Subject 2 / Grade 2 (including predicted grades)</label>
                <div class="flex-container">
                  <input id="aLevelSubject2" type="text" formControlName="aLevelSubject3" placeholder="Enter Subject 2">
                  <select id="aLevelGrade2" formControlName="aLevelGrade2">
                    <option *ngFor="let grade of aLevelGradeOptions" [value]="grade">{{grade}}</option>
                  </select>
                </div>
              </div>

              <div class="form-group">
                <label for="aLevelGrade3">*Subject 3 / Grade 3 (including predicted grades)</label>
                <div class="flex-container">
                  <input id="aLevelSubject3" type="text" formControlName="aLevelSubject3" placeholder="Enter Subject 3">
                  <select id="aLevelGrade3" formControlName="aLevelGrade3">
                    <option *ngFor="let grade of aLevelGradeOptions" [value]="grade">{{grade}}</option>
                  </select>
                </div>
              </div>

              <div class="form-group" *ngFor="let subject of additionalSubjects;">
                <label>Subject {{ subject.index}} / Grade {{  subject.index}} (including predicted grades)</label>
                <div class="flex-container">
                  <input type="text" placeholder="Enter Subject {{  subject.index}}">
                  <input type="text" placeholder="Enter Grade {{  subject.index}}">
                  <button type="button" (click)="removeAdditionalSubject(subject.index)">Remove</button>
                </div>
              </div>

              <button type="button" (click)="addAdditionalSubject()">Add Additional Grade</button>
            </div>

            <!-- A Level Results Section -->
            <!-- A-Level Equivalent Examinations Section -->

            <div *ngIf="isOtherEquivalentSelected()">
              <h3 class="section-subtitle smaller-heading">A-level Equivalent Qualifications e.g. AS-level, Scottish Highers</h3>


              <div class="form-group">
                <label for="otherLevel">*Level</label>
                <div class="flex-container">
                  <select id="otherLevel" formControlName="otherLevel">
                    <option *ngFor="let level of levelOptions" [value]="level">{{level}}</option>
                  </select>
                </div>
              </div>


              <div class="form-group">
                <label for="otherSubject1">Subject 1 / Grade 1 (including predicted grades)</label>
                <div class="flex-container">
                  <input id="otherSubject1" type="text" formControlName="otherSubject1" placeholder="Enter Subject 1">
                  <input id="otherGrade1" type="text" formControlName="otherGrade1" placeholder="Enter Grade 1">
                </div>
              </div>

              <div class="form-group">
                <label for="otherSubject2">Subject 2 / Grade 2 (including predicted grades)</label>
                <div class="flex-container">
                  <input id="otherSubject2" type="text" formControlName="otherSubject2" placeholder="Enter Subject 2">
                  <input id="otherGrade2" type="text" formControlName="otherGrade2" placeholder="Enter Grade 2">
                </div>
              </div>

              <div class="form-group">
                <label for="otherSubject3">Subject 3 / Grade 3 (including predicted grades)</label>
                <div class="flex-container">
                  <input id="otherSubject3" type="text" formControlName="otherSubject3" placeholder="Enter Subject 3">
                  <input id="otherGrade3" type="text" formControlName="otherGrade3" placeholder="Enter Grade 3">
                </div>
              </div>

              <div class="form-group" *ngFor="let subject of additionalEquivalentSubjects;">
                <label>Subject {{ subject.index}} / Grade {{  subject.index}} (including predicted grades)</label>
                <div class="flex-container">
                  <input type="text" placeholder="Enter Subject {{  subject.index}}">
                  <input type="text" placeholder="Enter Grade {{  subject.index}}">
                  <button type="button" (click)="removeAdditionalEquivalentSubject(subject.index)">Remove</button>
                </div>
              </div>

              <button type="button" (click)="addAdditionalEquivalentSubject()">Add Additional Grade</button>
            </div>

          </div>

          <div style=" background-color: rgb(156, 86, 6); height: 10px;">

          </div>

          <div class="section-container">
            <h3 class="section-title">Contextual Information</h3>
            <div class="form-group">
              <label for="localAuthority">*Have you ever been looked after by Local Authority Care?</label>
              <select id="localAuthority" formControlName="localAuthority">
                <option *ngFor="let option of yesNoDcaOutsideOptions" [value]="option">{{option}}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="refugee">*Did you come to this country as a refugee or asylum seeker?</label>
              <select id="refugee" formControlName="refugee">
                <option *ngFor="let option of yesNoDcaOptions" [value]="option">{{option}}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="freeMeals">*Have you ever been eligible for free school meals?</label>
              <select id="freeMeals" formControlName="freeMeals">
                <option *ngFor="let option of yesNoDcaOutsideOptions" [value]="option">{{option}}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="schoolType">*What type of secondary school did you attend?</label>
              <select id="schoolType" formControlName="schoolType">
                <option *ngFor="let option of schoolTypeOptions" [value]="option">{{option}}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="firstGenUni">*Are you part of the first generation in your immediate family to attend university?</label>
              <select id="firstGenUni" formControlName="firstGenUni">
                <option *ngFor="let option of yesNoDcaOptions" [value]="option">{{option}}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="russellGroup">*Did you complete your undergraduate degree at a Russell Group university? If you did not attend university (e.g. school leaver), please select ‘No’.</label>
              <select id="russellGroup" formControlName="russellGroup">
                <option *ngFor="let option of yesNoDcaOptions" [value]="option">{{option}}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="work1618">
                *How many hours did you work during your 16-18 education, throughout term time?
              </label>
              <select id="work1618" formControlName="work1618">
                <option *ngFor="let option of workHoursOptions" [value]="option">{{option}}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="workUni">
                *How many hours did you work during university, throughout term time? If you did not attend university (e.g. school leaver), how many hours did you work during school holidays?
              </label>
              <select id="workUni" formControlName="workUni">
                <option *ngFor="let option of workHoursOptions" [value]="option">{{option}}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="educationMedical">*Did you or an immediate family member have a serious medical or chronic condition that significantly impacted your education?</label>
              <select id="educationMedical" formControlName="educationMedical">
                <option *ngFor="let option of yesNoDcaOptions" [value]="option">{{option}}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="educationFamily">*Did you experience a bereavement that significantly impacted your education?</label>
              <select id="educationFamily" formControlName="educationFamily">
                <option *ngFor="let option of yesNoDcaOptions" [value]="option">{{option}}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="educationCarer">*Have you held any caring responsibilities that significantly impacted your education, such as caring for an elderly family member or a child under the age of 18?</label>
              <select id="educationCarer" formControlName="educationCarer">
                <option *ngFor="let option of yesNoDcaOptions" [value]="option">{{option}}</option>
              </select>
            </div>

            <div class="form-group">
              <label>*Did you live in the UK at the age of 16?</label>
              <div style="display: flex; align-items: center;">
                <label style="order: 1;">
                  <input type="radio" formControlName="ukResidency" value="Yes">
                </label>
                <div style="order: 2; margin-left: 20px;">
                  Yes
                </div>
              </div>
              <div style="display: flex; align-items: center;">
                <label style="order: 1;">
                  <input type="radio" formControlName="ukResidency" value="No">
                </label>
                <div style="order: 2; margin-left: 20px;">
                  No
                </div>
              </div>
            </div>

            <div class="form-group" *ngIf="showPostCode()">
              <label for="postcode">*What was your home postcode at the age of 16? Please enter in the following format ‘HA6 1SP’.</label>
              <input id="postcode" type="text" formControlName="postcode">
            </div>
          </div>

          <div *ngIf="!showPostCode()">
            <label>What was the occupation of your main household earner when you were aged about 14?</label><br />
            <div *ngFor="let occupation of occupations"  style="display: flex; align-items: center;">
              <label style="order: 1;">
                <input type="radio" [value]="occupation" formControlName="householdOccupation" />
              </label>
              <div style="order: 2; margin-left: 20px;">
                {{occupation}}
              </div>
            </div>
          </div>

          <label>The form will only allow submission if all fields with a asterisk (*) are completed</label>
          <button type="submit"
                  [style.background-color]="dandiForm.valid ? '' : 'red'"
                  [disabled]="isSubmitting || !dandiForm.valid" 
                  >
            Submit
          </button>

          <div *ngIf="dandiForm.invalid && dandiForm.dirty">
            <ul>
              <ng-container *ngFor="let control of controlLabels">
                <li *ngIf="dandiForm.get(control.controlName)?.errors?.required">
                  <span class="error-message">{{ control.label }} is required.</span>
                </li>
              </ng-container>
            </ul>
          </div>

        </form>
      </div>
    </div>
  </div>
  <div class="text-center mt-3">
    <p>
      Please contact <a href="mailto:info@dandilegal.co.uk" style="color: rgb(156, 86, 6); font-weight: bold;">info@dandilegal.co.uk</a> if you are experiencing any issues with our form.
    </p>
  </div>
</div>
